/**
 * Palette Picker
 */

// Gather all the shades
document.querySelectorAll('.shade-copy').forEach(function(shade) {

    // Add a listener
    shade.addEventListener('click', (event) => {

        // Get the colour
        let colourValue = shade.textContent.trim();

        // Copy the text
        navigator.clipboard.
            writeText(colourValue)
            .then(() => {
                console.log(colourValue + " copied");
            })
            .catch(() => {
                console.log(colourValue + " could not be copied");
            });
    })
})

/**
 * Dynamic Styleguide Navigation
 */

// Retrieving the Nav and Styleguide Sections
var nav = document.querySelector(".styleguide-nav")
var sections = document.querySelectorAll(".styleguide-item")

if (nav && sections) {
    for (section of sections) {

        // Create Required Elements
        let nav_item = document.createElement("a")
        let item_text = document.createElement("span")
        let item_dynamic = document.createElement("span")

        // Generate classes, including variations
        nav_item.className = " flex items-center justify-between w-full  bg-neutral-50 py-2 transition hover:bg-neutral-100 border-r-4 border-neutral-50 hover:border-primary-400"
        nav_item.className += section.getAttribute("variation") == "true" ? " pr-8 pl-12 " : " px-8 "
        item_text.className = " body-sm font-medium text-neutral-500 hover:text-neutral-600 "
        item_dynamic.className = section.getAttribute("dynamic") == "false" ? " w-2 h-2 rounded-full bg-primary-400 " : ""
        
        // Adding Content
        nav_item.href = "#" + section.id;
        item_text.innerText = section.getElementsByTagName("h1")[0].innerText

        // Append Content
        nav_item.appendChild(item_text)
        nav.appendChild(nav_item)
        nav_item.appendChild(item_dynamic)

    }
} else {
    console.log("Could not find the navigation or section elements.")
}